































import Vue from 'vue';
import VueConfetti from 'vue-confetti';
Vue.use(VueConfetti as any);

const nouns = [
  'caricature',
  'comic',
  'comedic',
  'clown',
  'fool',
  'con-artist',
  'mime',
  'skit',
  'crook',
  'bullshitter',
  'rannygazoo',
  'funniosity',
  'leg-puller',
  'trash-talker',
  'fool',
  'highballer',
  'overacter',
  'abomination',
  'joker',
  'busboy',
  'stink-bucket',
  'mop-head',
  'cannon-fodder',
  'hot-head',
  'witch',
  'geek',
  'troll',
  'sloth',

];

const adjectives = [
  'buck-toothed',
  'greasy',
  'bald',
  'chubby',
  'clean',
  'drab',
  'unfancy',
  'unfit',
  'flabby',
  'long',
  'lanky',
  'plain',
  'plump',
  'scruffy',
  'short',
  'skinny',
  'ugly',
  'unkempt',
  'unsightly',
  'festering',
  'ungifted',
  'hallowed',
  'unhelpful',
  'untalented',
  'vacant-minded',
  'inexpensive',
  'odd',
  'poor',
  'unimportant',
  'uninteresting',
  'vast',
  'wrong',
  'aggressive',
  'agreeable',
  'eager',
  'unfaithful',
  'unhappy',
  'manky',
  'silly',
  'failing',
  'angry',
  'bewildered',
  'clumsy',
  'defeated',
  'embarrassed',
  'fierce',
  'grumpy',
  'helpless',
  'itchy',
  'jealous',
  'lazy',
  'nervous',
  'obnoxious',
  'panicky',
  'pitiful',
  'repulsive',
  'scary',
  'thoughtless',
  'uptight',
  'worried',
  'chubby',
  'crooked',
  'flat',
  'hollow',
  'narrow',
  'refined',
  'round',
  'shallow',
  'skinny',
  'square',
  'steep',
  'straight',
  'wide',
  'sober',
  'comical',
  'dim',
  'overexaggerating',
  'ludicrous',
  'melodramatic',
  'jokey',
  'tragi-comic',
  'grandiose',
  'laughful',
  'clownish',
  'laughable',
  'crap',
  'tall',
  'bald',
  'odd',
  'bawdy',
  'gab',
  'facetious',
  'straight',
  'funny-looking',
  'droll',
  'cartoonish',
  'rhetoric',
  'nicey-nicey',
  'aggravating',
  'terror',
  'melodramatic',
  'noxious',
  'dim-witted',
  'stinky',
  'odorous',
];

import gql from 'graphql-tag';

export default Vue.extend({
  data() {
    return {
      nounList: nouns,
      adjectiveList: adjectives,
      bubbleanimation: false as boolean,
    };
  },
  methods: {
    startConfetti() {
      (this as any).$confetti.start({
        particles: [
            {
              type: 'heart',
            },
            {
              type: 'rect',
            },
          ],
        colors: ['#00a8ff', '#9c88ff', '#fbc531', '#4cd137', '#e84118', '#273c75'],
        particlesPerFrame: 1,
      });
      setTimeout(() => {
        this.stopConfetti();
      }, 6000);
    },
    stopConfetti() {
      (this as any).$confetti.stop();
    },
    getPassword(): string {
      if (localStorage.getItem('nobBetaTesterKey') !== null) {
        return localStorage.getItem('nobBetaTesterKey')!;
      }
      return this.passwordGenerator();
    },
    passwordGenerator(): string {
      const generatedPass = `${this.randomWord(adjectives)} ${this.randomWord(nouns)}`;
      this.discordPasswordBot(generatedPass);
      localStorage.setItem('nobBetaTesterKey', generatedPass);
      return generatedPass;
    },
    randomWord(array: string[]): string {
      const maxNumber = array.length - 1;
      const randomNumber = this.getRandomInt(maxNumber);
      return array[randomNumber];
    },
    getRandomInt(max: number) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    closeModal() {
      this.$emit('closeNob');
    },
    discordPasswordBot(password: string) {
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation($password: String!) {
          spirespyNobTesterPassDiscord(password: $password)
        }`,
        // Parameters
        variables: {
          password,
        },
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.bubbleanimation = true;
    }, 200);
    setTimeout(() => {
      this.startConfetti();
    }, 500);
  },
  beforeDestroy() {
    this.bubbleanimation = false;
  },
});
