















import Vue from 'vue';
import nobpopup from '../nobBetaTester.vue';

export default Vue.extend({
  name: 'AdminQuestionPopup',
  components: { nobpopup },
  data() {
    return {
      showPopup: false,
    };
  },
});
